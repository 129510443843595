html {
    --primary-background-color: #e5e7eb;
    --primary-background-active-color: #9ca3af;

    .gray {
        --primary-background-color: #e5e7eb;
        --primary-background-active-color: #9ca3af;
    }

    .slate {
        --primary-background-color: #e2e8f0;
        --primary-background-active-color: #94a3b8;
    }

    .zinc {
        --primary-background-color: #e4e4e7;
        --primary-background-active-color: #a1a1aa;
    }

    .neutral {
        --primary-background-color: #e5e5e5;
        --primary-background-active-color: #a3a3a3;
    }

    .stone {
        --primary-background-color: #e7e5e4;
        --primary-background-active-color: #a8a29e;
    }

    .red {
        --primary-background-color: #fecaca;
        --primary-background-active-color: #f87171;
    }

    .orange {
        --primary-background-color: #fed7aa;
        --primary-background-active-color: #fb923c;
    }

    .amber {
        --primary-background-color: #fde68a;
        --primary-background-active-color: #fbbf24;
    }

    .yellow {
        --primary-background-color: #fef08a;
        --primary-background-active-color: #facc15;
    }

    .lime {
        --primary-background-color: #d9f99d;
        --primary-background-active-color: #a3e635;
    }

    .green {
        --primary-background-color: #bbf7d0;
        --primary-background-active-color: #4ade80;
    }

    .emerald {
        --primary-background-color: #a7f3d0;
        --primary-background-active-color: #34d399;
    }

    .teal {
        --primary-background-color: #99f6e4;
        --primary-background-active-color: #2dd4bf;
    }

    .cyan {
        --primary-background-color: #a5f3fc;
        --primary-background-active-color: #22d3ee;
    }

    .sky {
        --primary-background-color: #bae6fd;
        --primary-background-active-color: #38bdf8;
    }

    .blue {
        --primary-background-color: #bfdbfe;
        --primary-background-active-color: #60a5fa;
    }

    .indigo {
        --primary-background-color: #c7d2fe;
        --primary-background-active-color: #818cf8;
    }

    .violet {
        --primary-background-color: #ddd6fe;
        --primary-background-active-color: #a78bfa;
    }

    .purple {
        --primary-background-color: #e9d5ff;
        --primary-background-active-color: #c084fc;
    }

    .fuchsia {
        --primary-background-color: #f5d0fe;
        --primary-background-active-color: #e879f9;
    }

    .pink {
        --primary-background-color: #fbcfe8;
        --primary-background-active-color: #f472b6;
    }

    .rose {
        --primary-background-color: #fecdd3;
        --primary-background-active-color: #fb7185;
    }
}

.root-div {
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
}

#launcher-frame {
    visibility: hidden !important;
}

#freshworks-frame-wrapper {
    right: calc(50% - 144px) !important;
    max-height: 685px !important;
}

.old-facility-row,
.locked-physician-row,
.red-inventory-row {
    background-color: #cc696952 !important;
}

.green-inventory-row {
    background-color: #51a351 !important;
}

.yellow-inventory-row {
    background-color: rgba(255, 255, 0, 0.4) !important;
}

.cancelled-requisition {
    background-color: #80808087 !important;
}

.passed-24hours-requisition {
    background-color: #fd9d9d !important;
}

.color-label {
    width: 30px !important;
    height: 30px !important;
}

.incorrect-patient-insurance-item {
    background-color: rgba(255, 0, 0, 0.6) !important;
}

.incorrect-patient-item {
    background-color: rgba(255, 0, 0, 0.4) !important;
}

.incorrect-insurance-item {
    background-color: rgba(255, 0, 0, 0.2) !important;
}

.order-1 {
    order: 1;
}

.order-2 {
    order: 2;
}

.order-3 {
    order: 3;
}

.communiationDetailText textarea.MuiInputBase-input {
    color: black;
}

.communicate_date {
    text-align: end;
    padding: 1rem;
    margin-bottom: -50px;
}

.communicate_date_reply {
    text-align: end;
    margin-bottom: -20px;
}

.unReplyBtn {
    padding: 0px;
    border: none;
    background: transparent;
    line-height: 1rem;
    margin-right: 0.5rem;
}

/* patient portal dashboard insuracne tab */
table#insurance-table {
    thead {
        tr {
            background: #0369b3;
        }

        th {
            color: white;
            padding: 15px 5px;
            text-align: center;
        }
    }

    td {
        min-height: 2rem;
        padding: 10px 5px;
        border-left: 1px solid white;
        border-right: 1px solid white;
        text-align: center;
    }

    tbody {
        tr:nth-of-type(even) {
            background: #e9e9e9;
        }
    }
}

.edit-order-btn .MuiBadge-root .MuiBadge-badge {
    margin-top: -6px;
    right: -10px;
}

/* patient portal dashboard results tab */
table#result-table {
    thead {
        tr {
            background: #0369b3;
        }

        th {
            color: white;
            padding: 15px 5px;
            text-align: center;
        }
    }

    td {
        min-height: 2rem;
        padding: 10px 5px;
        border-left: 1px solid white;
        border-right: 1px solid white;
        text-align: center;

        .resultAction_bar {
        }
    }

    tbody {
        tr:nth-of-type(even) {
            background: #e9e9e9;
        }
    }
}

@media (max-width: 1080px) {
    table#insurance-table {
        display: block;

        thead {
            display: block;

            tr {
                position: absolute;
                top: -9999px;
                left: -9999px;
            }
        }

        tbody {
            display: block;
        }

        tr {
            display: block;
            margin: 0 0 1rem 0;
        }

        tr:nth-child(even) {
            background: #e9e9e9;
        }

        td {
            display: block;
            border: none;
            border-bottom: 1px solid #eee;
            position: relative;
            padding-left: 50%;
            display: flex;
            align-items: center;
        }

        td:before {
            /* Now like a table header */
            position: absolute;
            /* Top/left values mimic padding */
            top: 0;
            left: 0px;
            width: 45%;
            font-weight: 600;
            padding-right: 10px;
            padding-left: 10px;
            height: 100%;
            white-space: nowrap;
            display: flex;
            align-items: center;
            line-height: 2.5rem;
            background: #0369b3;
            color: white;
        }

        td:nth-of-type(1):before {
            content: "Test Name";
        }

        td:nth-of-type(2):before {
            content: "Collection Date";
        }

        td:nth-of-type(3):before {
            content: "Sample ID";
        }

        td:nth-of-type(4):before {
            content: "Patient Name";
        }

        td:nth-of-type(5):before {
            content: "Facility Name";
        }

        td:nth-of-type(6):before {
            content: "Physician Name";
        }

        td:nth-of-type(7):before {
            content: "Chart Number";
        }

        td:nth-of-type(8):before {
            content: "Total Amount";
        }

        td:nth-of-type(9):before {
            content: "Adjustment Amount";
        }

        td:nth-of-type(10):before {
            content: "Paid";
        }

        td:nth-of-type(11):before {
            content: "Balance Due";
        }

        td:nth-of-type(12):before {
            content: "Due By";
        }

        td:nth-of-type(13):before {
            content: "Pay Amount";
        }
    }
}

@media (max-width: 860px) {
    /* Patient portal dashboard result tab*/
    table#result-table {
        display: block;

        thead {
            display: block;

            tr {
                position: absolute;
                top: -9999px;
                left: -9999px;
            }
        }

        tbody {
            display: block;
        }

        td {
            display: block;
            border: none;
            border-bottom: 1px solid #eee;
            position: relative;
            padding-left: 50%;
            align-items: center;
        }

        tr {
            margin: 0 0 1rem 0;
            display: block;
        }

        tr:nth-child(even) {
            background: #e9e9e9;
        }

        td:before {
            position: absolute;
            top: 0;
            left: 0px;
            width: 45%;
            font-weight: 600;
            padding-right: 10px;
            padding-left: 10px;
            height: 100%;
            white-space: nowrap;
            display: flex;
            align-items: center;
            line-height: 2.5rem;
            background: #0369b3;
            color: white;
        }

        td:nth-of-type(1):before {
            content: "Test Name";
        }

        td:nth-of-type(2):before {
            content: "Testing Facility";
        }

        td:nth-of-type(3):before {
            content: "Physician";
        }

        td:nth-of-type(4):before {
            content: "Requisition ID";
        }

        td:nth-of-type(5):before {
            content: "Sample ID";
        }

        td:nth-of-type(6):before {
            content: "Collection Date";
        }

        td:nth-of-type(7):before {
            content: "Release Date";
        }

        td:nth-of-type(8):before {
            content: "Sample Tracking";
        }

        td:nth-of-type(9):before {
            content: "Result";
        }
    }
}

.dashboard-menu ul li.dashboardFooter {
    position: inherit;
}

/* Image Select Component Style  */

.notifyType_imageBar {
    padding: 15px;
    border: 1px solid;

    .nofityType_imagePicker {
        border: none;
        border-radius: 6px;
        font-weight: 500;
        font-size: 15px;
        padding: 1rem 0;
    }

    #file-screenshot {
        display: none;
    }

    img {
        height: 250px;
        width: fit-content;
        max-width: revert;
        margin: auto;
    }
}

/* Barcode Printing Style  */

.target-barcode {
    width: calc(100% - 55px);
    position: absolute;
    height: calc(100% - 60px);
    border: solid 2px red;
    z-index: 100;
    right: 27px;
    top: 27px;
}

/* MuiTextField, Form-Control Select Component Invalid Style  */

.MuiTextField-root {
    width: 100%;
}

.form-control {
    &.select.is-invalid {
        background-image: none;
    }

    &.select.is-valid {
        background-image: none;
    }

    &.select:invalid {
        background-image: none;
    }

    &.select:valid {
        background-image: none;
    }
}

.hide-valid-style.form-control:valid {
    border-color: #dcdcdc !important;
    background-image: none !important;
}

/* Invoice Page Style  */

.init_invoice_label {
    font-size: 20px;
    font-weight: bold;
}

.invoice-label {
    font-size: 40px;
    color: #4ba3e2;
    margin: 10px 0px;
}

.invoice-state-label {
    font-size: 20px;
    font-weight: bold;
}

.invoice-grid .ag-cell {
    line-height: 35px;
}

.history-row {
    padding: 5px;
}

.history-date {
    font-size: 18px;
}

.history-description {
    font-size: 16px;
    word-break: break-all;
}

/* Toastr Success Background Style  */

.toast-success {
    background-color: #51a351 !important;
}

/* Self-Check-In Pages, Main Page Style  */

.banner-text {
    font-size: 2vmax;
    text-align: center;
    padding: 20px;
    font-weight: 500;
}

.paragraph_html {
    font-size: 18px;

    p {
        margin-bottom: 0px;
    }
}

.thankyou_step {
    font-size: 18px;
    text-align: center;

    a {
        color: blue;
        text-decoration: underline;
    }
}

.mapPopup {
    position: relative !important;
    width: 100% !important;
    height: 100% !important;
    min-height: 220px !important;

    .titleCont {
        width: auto;
    }
}

p.doc-name1 {
    font-size: 20px !important;
    font-weight: 600;
    color: #0168b3;
    cursor: pointer;
}

.doc-location p {
    margin-bottom: 5px;
}

.apt-btn1.left {
    color: #007bff;
    font-weight: 600;
    font-size: 15px;
}

.vip-reserve-form {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;

    h3 {
        text-align: center;
    }

    .pay-btn {
        font-weight: bold;
        height: 50px;
    }
}

/* Client Onboarding Box Style */

.input-box {
    border: 1px solid black;
    border-radius: 10px;
    padding: 10px !important;
    margin: 5px 0px;
}

/* Idle Layout Style */

.idle-overlay {
    height: 100%;
    position: fixed;
    z-index: 999999999999999;
    left: 0;
    top: 0;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.9);
    overflow-x: hidden;
    transition: 0.5s;

    .overlay-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 100vh;
        text-align: center;
    }

    p {
        text-decoration: none;
        font-size: 36px;
        color: white;
        display: block;
    }
}

@media screen and (max-height: 450px) {
    .idle-overlay p {
        font-size: 20px;
    }
}

/* Terms Agree Style */

.terms-agree {
    .MuiListItem-button:hover {
        background: none !important;
    }

    a {
        color: red;
    }
}

/* Custom Mat CheckBox Style */

.mat-checkbox-custom {
    padding-left: 0;
    position: relative;

    li.MuiListItem-root,
    span.MuiButtonBase-root {
        padding-left: 0 !important;
    }
}

/* Facility Regular Time Picker Style */

.regular-time-picker-row {
    display: flex;
    align-items: center;
    text-align: center;
    margin-bottom: 5px;
}

/* Driver Map SuperAdmin View Style */

.map-view-driver-select-component {
    width: 300px;
    padding-top: 10px;
}

.fc-event-title {
    text-overflow: ellipsis;
}

.fc-popover {
    z-index: 1000 !important;
}

/* Driver Map View Style */

.map-dashboard-content .map-row .map {
    width: calc(100% - 30px) !important;
}

.route-checkbox {
    display: flex;
    align-items: center;
}

.create-route-btn {
    position: absolute;
    bottom: 10px;
    width: calc(100% - 20px);

    .btn {
        width: calc(100% - 20px);
    }
}

.route-template-tab-list {
    .MuiTabs-scroller {
        overflow-x: auto !important;
    }
}

/* Footer Style */

.footer-logo-div {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Custom modal dialog for table view */

.custom-map-modal {
    .modal-dialog {
        width: 100%;
        height: 100%;
        max-width: none;
        margin: 0;
        padding: 0;
    }

    .modal-content {
        height: auto;
        min-height: 100%;
        border-radius: 0;
    }
}

.table-nav {
    padding-right: 580px !important;

    &.nav-tabs {
        border-bottom: 1px solid #dee2e6;

        .nav-link {
            margin-bottom: -1px;
            border: 1px solid transparent;
            border-top-left-radius: 0.25rem;
            border-top-right-radius: 0.25rem;
        }

        .nav-item.show .nav-link,
        .nav-link.active {
            border-color: #dee2e6 #dee2e6 #fff;
        }

        .nav-link:focus,
        .nav-link:hover {
            background-color: transparent;
        }
    }
}

.grid-buttons.absolute-buttons {
    position: absolute;
    right: 0px;
    margin-top: 15px;
    margin-right: 10px;
}

/* clinic portal dashboard */
/*-----------------
	*** Clinic Dashboard ***
-----------------------*/
.header {
    width: 100%;
    top: 0;
    z-index: 999;
    min-height: 77px;
}

.block-ui-container {
    position: fixed;
    z-index: 999999;
}

.clinicHeader {
    top: 0px;
    z-index: 999;
    border-bottom: 1px solid var(--primary-background-color);
}

.breadcrumb-btns {
    justify-content: flex-end;
}

.breadcrumb-text {
    margin-bottom: 0px;
}

.export-ul {
    list-style: none;
    display: inline-flex;
    align-items: center;
    margin-bottom: 0px;
    padding-inline-start: 0px;
    flex-wrap: wrap;

    li {
        padding: 0 10px;
        font-weight: 500;
        cursor: pointer;
        border-left: 1px solid;
    }

    li:first-child {
        border-left: none;
    }
}

.filter-select-bar {
    width: 100%;
    margin-top: 8px !important;
    margin-bottom: 15px !important;
}

.filter-search-bar {
    width: 100%;
}

.clinic-wrap {
    display: flex;
    min-height: calc(100vh - 78px);
    justify-content: space-between;
}

.clinic-contain {
    width: calc(100% - 290px);
}

.clinic-sidebar {
    left: 0;
    margin-top: 0px;
    width: 290px;
    padding-bottom: 20px;
    transition: all 0.2s;
    background: var(--primary-background-color);
    z-index: 998;
}

.clinicboard-menu {
    margin-top: 65px;
    transition: all 0.2s;
}

.clinicMenu {
    font-size: 16px;
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: relative;

    li {
        margin-bottom: 0px;
        position: relative;

        a {
            align-items: center;
            border-radius: 3px;
            display: flex;
            justify-content: flex-start;
            padding: 6px 15px;
            position: relative;
            transition: all 0.2s;
            cursor: pointer;
            white-space: nowrap;
        }
    }
}

.groupMenu a {
    .menu-arrow {
        transition: all 0.2s ease-in-out 0s;
        position: absolute;
        right: 13px;
    }

    &.subdrop .menu-arrow {
        transform: rotate(90deg);
    }
}

.clinic-sidebar .clinicMenu .groupMenu .submenu {
    display: none;
}

.clinic-sidebar .clinicMenu .groupMenu .submenu.active {
    display: block;
}

.clinicMenu li a:hover {
    color: #fff;
    background: var(--primary-background-active-color);
}

.clinicMenu li.active a {
    background-color: var(--primary-background-active-color);
    color: #fff;
}

.clinicMenu li ul {
    font-size: 14px;
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: relative;
}

.clinicMenu li ul li {
    padding-left: 15px;
}

#expand_btn {
    font-size: 25px;
    cursor: pointer;
}

.miniside_menu {
    .clinic-contain {
        margin-left: 50px;
        width: calc(100% - 50px);
    }

    .clinic-sidebar {
        width: 55px !important;
        position: fixed;
        height: -webkit-fill-available;
        z-index: 999;

        .clinicMenu .groupMenu {
            a {
                span.menuLabel {
                    visibility: hidden;
                }

                span.menu-arrow {
                    visibility: hidden;
                }

                i {
                    margin-right: 0px !important;
                }
            }

            .submenu {
                display: none;

                &.active {
                    display: none;
                }

                li a {
                    visibility: hidden;
                }
            }
        }
    }

    .clinic-sidebar:hover {
        width: 290px !important;

        .clinicMenu .groupMenu {
            a span.menuLabel {
                visibility: visible;
            }

            a span.menu-arrow {
                visibility: visible;
            }

            a i {
                margin-right: 0.5rem !important;
            }

            .submenu.active {
                display: block;
            }

            .submenu li a {
                visibility: visible;
            }
        }
    }
}

.breadcrumb-pagesize {
    width: 100px;
    background-color: transparent;
}

.universal-mobile {
    display: none;
}

.universal-desktop {
    margin-right: 10px;
}

@media only screen and (max-width: 991.98px) {
    .clinic-wrap {
        flex-wrap: wrap;
    }

    .universal-desktop {
        display: none;
    }

    .universal-mobile {
        display: flex;
        padding: 10px 15px;
        width: 100vw;
        align-items: center;
        justify-content: space-between;
    }

    #simple-popover {
        .MuiPopover-paper {
            width: 100vw;
            left: 16px;
            top: 16px;
        }
    }

    .clinic-sidebar {
        left: 0;
        top: 77px;
        position: fixed;
        height: calc(100% - 55px);
        z-index: 999;
        transform: scale(0);
        transition: all 0s;
        background: #47474752;
    }

    .clinicboard-menu {
        background: var(--primary-background-color);
        margin-top: 0;
        width: 290px;
        overflow-y: auto;
        margin-left: -290px;
        transition: all 0.2s;
        height: 100%;
    }

    #expand_btn {
        display: none;
    }

    .clinic-contain {
        width: 100% !important;
    }

    .miniside_menu {
        .clinic-contain {
            margin-left: 0px;
        }

        .clinic-sidebar {
            width: 100% !important;
            position: fixed;
            height: 100%;
            z-index: 999;

            .clinicMenu .groupMenu {
                a span {
                    visibility: visible;
                }

                a i {
                    margin-right: 0.5rem !important;
                }

                .submenu li a {
                    visibility: visible;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .pdfWebView {
        display: none;
    }

    .pdfMobileView {
        display: block;
    }

    .location-left-section {
        flex-wrap: wrap;
    }

    /* patient portal mobile responsive */
    .qr-Image {
        left: 0;
        float: left;
        margin-top: -15px;
    }

    .qr-expandbtn {
        cursor: pointer;
        position: absolute;
        top: 126px;
    }

    .patient-sampleId {
        width: 60%;
        float: right;
        text-align: start;
    }

    .patient-name {
        margin-top: 30px;
    }

    .table-nav {
        padding-right: 0.5rem !important;
    }

    .grid-buttons.absolute-buttons {
        position: relative !important;
        margin: auto !important;
        margin-top: 19px !important;
    }

    .breadcrumb-btns {
        justify-content: start !important;
        margin-left: 0;
    }

    .dashboard-menu ul {
        li.dashboardItem {
            display: none;
        }

        li.dashboardLogout {
            display: none;
        }

        li.dashboardFooter {
            display: none;
        }
    }

    .mobile-patient-footer-section {
        display: block !important;
    }
}

@media (min-width: 768px) {
    .pdfWebView {
        display: block;
    }

    .pdfMobileView {
        display: none;
    }

    .login-content {
        min-height: calc(100vh - 212px);
    }

    .location-left-section {
        flex-wrap: nowrap;
    }

    .map-dashboard-content {
        min-height: calc(100vh - 112px);

        .map-row {
            height: calc(100vh - 200px);
        }

        .order-list {
            height: 100%;
            overflow-y: auto;
            margin-bottom: 40px;
        }
    }

    .logo-big {
        margin-right: 10px;
    }
}

/* Header Items Style  */

.show-always {
    display: block !important;
}

i#mobile_btn {
    color: #ee344e;
    font-size: 27px;
}

.header-icon {
    color: #0369b3;
    cursor: pointer;
    margin-right: 10px;
}

@media (max-width: 575px) {
    .logo-big {
        display: none;
    }

    .logo-small {
        display: block;
        margin-left: 30px;
    }

    .upload-btn {
        width: 100%;
    }
}

@media (min-width: 575px) and (max-width: 680px) {
    .logo-big {
        display: none;
    }

    .logo-small {
        display: block;
    }
}

@media (min-width: 680px) {
    .logo-big {
        display: block;
    }

    .logo-small {
        display: none;
    }
}

@media (max-width: 400px) {
    .header-icon {
        margin-right: 3px;
    }

    .fa-2x {
        font-size: 22px !important;
    }

    /* patient portal mobile responsive */
    .qr-Image {
        width: 110px;
        height: 110px;
        margin-top: 0px;
        margin-top: -5px;
        margin-left: -7px;
    }

    .qr-expandbtn {
        font-size: 13px !important;
        text-align: center;
    }

    .patient-sampleId {
        font-size: 15px !important;
        margin-bottom: 0;
        padding-bottom: 0;
        margin-top: 5px;
    }

    .receiveTrackBtn {
        font-size: 0.8rem !important;
    }
}

.menu-opened .clinic-sidebar {
    transform: scale(1);
}

.menu-opened .clinicboard-menu {
    /* transform: translateX(0); */
    margin-left: 0px;
    padding-top: 19px;
    transition: all 0.2s;
}

/* patient portal dashboard page css */

.schedule-header {
    border-bottom: 1px solid #c9c9c9;
}

.schedule-nav ul.nav-tabs {
    margin-bottom: -10px !important;
    display: flex;
    list-style: none;
}

.schedule-nav .nav-tabs li.nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
    padding: 10px 20px;
    margin-bottom: 0 !important;
    border-radius: 0;
    border-color: #ffffff00;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    cursor: pointer;
}

.schedule-nav .nav-tabs li.active {
    /* border: 1px solid #ff4877 !important; */
    border-color: #c9c9c9 #c9c9c9 #ffffff #c9c9c9;
    color: #494949;
}

.dateRangeBar {
    width: 100%;
    max-width: 480px;
    padding-left: 8px;
    padding-right: 8px;
}

.dateRangeBarPickers {
    display: flex;
}

.dateRangeBtn {
    width: 108px;
}

@media (min-width: 2700px) {
    .filter-select-bar {
        width: 50%;
        margin-top: 8px !important;
        margin-bottom: 15px !important;
        max-width: 50%;
    }

    .filter-search-bar {
        width: calc(50% - 15px);
        margin-left: 15px !important;
        max-width: 50%;
    }
}

.sigCanvas {
    width: 100%;
    border-radius: 10px;
    border: 2px solid black;
}

.universal-search {
    margin-right: 15px !important;

    .MuiOutlinedInput-adornedEnd {
        padding-right: 0;
    }
}

.search-item-container {
    .search-item-sample {
        font-weight: bold;
    }

    .search-item-lab,
    .search-item-patient {
        font-size: 14px;
    }

    .search-item-dot {
        width: 30px;
        height: 30px;
        border-radius: 30px;
    }

    .search-item-order {
        background-color: green;
    }

    .search-item-requisition {
        background-color: #ee344e;
    }
}

#insurance-provider-field {
    padding: 4px !important;
}

.resultFilterMenuButton {
    padding: 10px 15px;
}

.resultFilterMenu {
    .resultFilterSubMenuItem {
        flex-direction: column;
        align-items: start !important;
        padding: 5px 10px !important;

        .resultItem {
            padding: 3px;
            width: 100%;
        }

        .resultItem:hover {
            background-color: #ebebeb;
        }
    }

    .resultFilterSubMenuItem:hover {
        background-color: white;
    }

    .saveCloseMenu {
        background-color: #0369b3;
        color: white;
        padding-left: 26px !important;
    }

    .saveCloseMenu:hover {
        background-color: #028ee1;
        color: white;
    }
}

.rotate-btn-group {
    display: flex;
    justify-content: center;
    margin-top: 15px;

    .rotate-btn {
        display: flex;
        flex-direction: column;
        margin: 0 10px;

        .MuiIconButton-root {
            padding: 0;
        }

        .rotate-deg-label {
            font-size: 12px;
        }
    }
}

.sub_panel_order_view_popup {
    .modal-dialog {
        display: flex;
        justify-content: center;

        .modal-content {
            width: 95%;
            box-shadow: 0 0px 40px rgb(0 0 0 / 90%);
        }
    }
}

.MuiList-padding {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

#menu-routeTemplateId {
    ul {
        padding: 0 !important;
    }
}

.modal-button-col {
    padding-top: 10px;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.insurance-information-container,
.insurance-provider-input {
    background-color: #eee;
}

.MuiList-root {
    padding: 0 !important;
}

.MuiAutocomplete-listbox {
    padding: 0 !important;
}

#dropdown-basic {
    padding: 10px 0px !important;
}

.ag-theme-alpine {
    font-family: "Poppins", sans-serif;
}

.ag-theme-alpine-dark {
    font-family: "Poppins", sans-serif;
}

.cursorPointer {
    cursor: pointer;
}

.password-eye-icon {
    position: absolute;
    top: 40px;
    right: 25px;
    font-size: 20px;
}

.login-right.tabs {
    .nav-link.active {
        background-color: #eee !important;
    }

    .nav-tabs {
        padding: 5px;
    }
}
